import React from 'react'
import { t } from 'ttag'
import { ThemeContextConsumer } from '../../ThemeContext'
import { Overlay } from '@react-md/overlay'
import { connect } from 'react-redux'
import { actionCloseModal } from '../../../actions/modal'
import {
  Contents,
  Caption,
  Modal,
  SubmitButton,
  Paragraph,
  ButtonContainer,
  Text,
  AlertContent,
} from './styled'
import { formatCurrency } from '../../selectors/currency'
import { IChallengeDashboardData } from '../../Dashboard/interfaces'
import moment from 'moment'
import { actionCashOut } from '../../../actions/wallets'

interface ICashOut {
  activeWallet: any
  challenge: IChallengeDashboardData
  trades: any
  formatCurrency: (value: any) => string
  actionCashOut: (data: any) => void
  actionCloseModal: () => void
}

function getMinDaysFromNow(startTime: any) {
  return Math.abs(
    moment(startTime).startOf('day').diff(moment().startOf('day'), 'days')
  )
}

function isSamePeriod(startDate: any, type: any) {
  const start = moment.utc(startDate)
  const current = moment().utc()

  if (type === 'Day') {
    return start.isSame(current, 'day')
  } else if (type === 'Week') {
    return start.isoWeek() === current.isoWeek()
  } else if (type === '2 Weeks') {
    return (
      Math.floor((start.isoWeek() - 1) / 2) ===
      Math.floor((current.isoWeek() - 1) / 2)
    )
  } else if (type === 'Month') {
    return start.month() === current.month()
  } else if (type === 'Year') {
    return start.year() === current.year()
  }

  return false
}

const CashOut = (props: ICashOut) => {
  const { trades, challenge, activeWallet } = props
  const cashoutAmount =
    Math.abs(activeWallet?.availableCash - challenge?.state?.initialBalance) ||
    0

  const handleCashOut = () => {
    if (activeWallet.challengeID) {
      props.actionCashOut({
        currentChallengeId: activeWallet.challengeID,
        amount: cashoutAmount,
      })
    }
  }

  const handleClose = () => {
    props.actionCloseModal()
  }

  const cash = props.formatCurrency(cashoutAmount)

  const isTradesOpen = trades?.open?.length > 0 || trades?.pending?.length > 0

  const isConditionMatched =
    trades?.closed?.length >= challenge?.state?.cashoutMinTrades &&
    getMinDaysFromNow(challenge.state.startTime) >=
      challenge?.state?.cashoutMinDays &&
    activeWallet?.availableCash - challenge?.state?.initialBalance >=
      challenge?.state?.cashoutMinAmount &&
    challenge?.state?.lastCashout
      ? !isSamePeriod(
          challenge?.state?.lastCashout,
          challenge.state.cashoutFrequency
        )
      : true

  const renderTradeMessage = () => {
    if (trades?.open?.length > 0 && trades?.pending?.length > 0) {
      return t`Please close all open positions or cancel all pending trades before requesting a cashout`
    } else if (trades?.open?.length > 0) {
      return t`Please close all open positions trades before requesting a cashout`
    } else if (trades?.pending?.length > 0) {
      return t`Please cancel all pending trades before requesting a cashout`
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      if (isTradesOpen) {
        handleClose()
      }
    }, 3000)
  }, [isTradesOpen])

  return (
    <>
      <Modal isTradesOpen={isTradesOpen}>
        <div>
          <ThemeContextConsumer>
            {(context) => (
              <>
                {isTradesOpen ? (
                  <AlertContent colors={context}>
                    <Text color={context}>{renderTradeMessage()}</Text>
                  </AlertContent>
                ) : (
                  <Contents backgroundColor={context.modalBackground}>
                    {!isConditionMatched ? (
                      <>
                        <Caption
                          color={context.primaryText}
                        >{t`Cannot continue with Cash-Out`}</Caption>
                        <Paragraph colors={context}>
                          {t`Reason:`}
                          {Number(challenge?.state?.cashoutMinTrades) >
                            trades?.closed?.length && (
                            <>
                              <br />
                              {t`Minimum number of trades not met ${challenge.state.cashoutMinTrades}`}
                            </>
                          )}
                          {challenge?.state?.cashoutMinDays >
                            getMinDaysFromNow(challenge.state.startTime) && (
                            <>
                              <br />
                              {t`Minimum number of days not met (Min Days: ${challenge.state.cashoutMinDays})`}
                            </>
                          )}
                          {challenge?.state?.cashoutMinAmount >
                            activeWallet?.availableCash -
                              challenge?.state?.initialBalance && (
                            <>
                              <br />
                              {challenge.state.cashoutMinAmount &&
                                t`Minimum amount for Cash-Out not met ${props.formatCurrency(
                                  Number(challenge.state.cashoutMinAmount)
                                )}`}
                            </>
                          )}
                          {challenge?.state?.lastCashout &&
                            isSamePeriod(
                              challenge?.state?.lastCashout,
                              challenge.state.cashoutFrequency
                            ) && (
                              <>
                                <br />
                                {t`Minimum time between Cash-Out not met ${challenge.state.cashoutFrequency}`}
                              </>
                            )}
                        </Paragraph>
                        <SubmitButton colors={context} onClick={handleClose}>
                          {t`Close`}
                        </SubmitButton>
                      </>
                    ) : (
                      <>
                        <Caption
                          color={context.primaryText}
                        >{t`You are requesting a Cash-Out of ${cash}`}</Caption>
                        <Paragraph colors={context}>
                          {t`This Cash-Out needs to be approved by the administrator.`}
                          <br />
                          {t`All your accounts will be frozen until approval or rejection of Cash-Out.`}
                        </Paragraph>
                        <Paragraph colors={context}>
                          {t`Do you wish to proceed?`}
                        </Paragraph>
                        <ButtonContainer>
                          <SubmitButton colors={context} onClick={handleClose}>
                            {t`No`}
                          </SubmitButton>
                          <SubmitButton
                            yesButton={true}
                            colors={context}
                            onClick={handleCashOut}
                          >
                            {t`Yes`}
                          </SubmitButton>
                        </ButtonContainer>
                      </>
                    )}
                  </Contents>
                )}
              </>
            )}
          </ThemeContextConsumer>
        </div>
      </Modal>
      <Overlay
        id="modal-overlay"
        visible={true}
        onRequestClose={props.actionCloseModal}
        style={{ zIndex: 40 }}
      />
    </>
  )
}

const mapStateToProps = (state: any) => ({
  activeWallet: state.wallets.activeWallet,
  trades: state.trades,
  challenge: state.wallets.challengeDashboard,
  formatCurrency: formatCurrency(state),
})

export default connect(mapStateToProps, {
  actionCloseModal,
  actionCashOut,
})(CashOut)
